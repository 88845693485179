<template>
  <div class="chatgpt">
    <div class="chatgpt-cont">
      <div class="chatgpt-cont-l">
        <div
          class="chatgpt-cont-top"
          :style="{ overflow: !allowScroll ? 'hidden' : 'auto' }"
        >
          <div class="loading-box" v-if="listLoading">
            <img
              src="../../assets/images/new_icon/loading.png"
              class="loading"
              width="29"
              height="30"
            />
          </div>
          <!--        <div class="message-type">- 历史消息 -</div>-->
          <div v-for="item in list" :key="item.id">
            <div class="align-right">
              <div class="message-cont" v-if="item.keyword">
                <div class="message-cont-date">{{ item.created_at }}</div>
                <div class="message-cont-nr">{{ item.keyword }}</div>
              </div>
            </div>
            <div class="align-left" v-if="item.result">
              <div class="message-cont">
                <div class="message-cont-date">{{ item.updated_at }}</div>
                <div class="message-cont-nr">{{ item.result }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="chatgpt-cont-bottom">
          <div
            style="
              margin-bottom: 5px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <el-radio-group v-model="gpttype" @change="radioChange">
              <el-radio
                v-for="item in typeList"
                :key="item.id"
                :label="item.id"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>

            <Checkbox v-model="context" style="margin-left: 30px"
              >上下文</Checkbox
            >
          </div>

          <div class="chatgpt-cont-bottom-cont">
            <div class="chatgpt-cont-bottom-cont-text">
              <Input
                v-model="questionTxt"
                type="textarea"
                :rows="2"
                placeholder="来提问点什么吧...   （Enter直接发送）"
                @on-keydown="Keydown"
              />
            </div>
            <div class="chatgpt-cont-bottom-cont-btn" @click="sendMessage">
              <img src="../../assets/images/new_icon/send.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="zhedie">
        <div v-show="isFold" @click="isFold = false">《</div>
        <div v-show="!isFold" @click="isFold = true">》</div>
      </div>
      <div class="chatgpt-temp" v-show="!isFold">
        <p class="chatgpt-temp-tit">请选择模板提问</p>
        <div class="chatgpt-temp-list">
          <div
            class="chatgpt-temp-list-item"
            v-for="item in templateList"
            :key="item.id + 'temp'"
            @click="selectTemp(item)"
          >
            <p class="chatgpt-temp-list-item-tit">{{ item.name }}</p>
            <div>{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <!--  <div class="chatgpt-cont">-->
    <!--    <div class="item" v-for="(item,index) in dataList" :key="index">-->
    <!--      <div class="tit">-->
    <!--        <div class="tit-cont">-->
    <!--          <div>问题：</div>-->
    <!--          <div>{{item.title}}</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="des">-->
    <!--        <div class="des-cont" >-->
    <!--          <div>答案：</div>-->
    <!--          <div style="position: relative">-->
    <!--            {{item.desc}}-->
    <!--            <Spin v-if="index == dataList.length - 1 && loading"></Spin>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div v-if="!dataList.length" class="no-data">请开始提问，根据问题差异响应时间大概在一分钟左右，请耐心等待。</div>-->
    <!--  </div>-->

    <!--  <div class="bottom">-->
    <!--    <div class="bottom-cont">-->
    <!--      <Input v-model="questionTxt" type="textarea" icon="ios-paper-plane-outline" placeholder="请输入问题" class="bottom-inp"   />-->
    <!--      <Button type="primary" @click="getChatgpt">发送</Button>-->
    <!--    </div>-->

    <!--  </div>-->
  </div>
</template>

<script>
import Cookies from "js-cookie";
import util from "@/utils/tools.js";
export default {
  name: "chatGPT.vue",
  data() {
    return {
      questionTxt: "",
      dataList: [],
      loading: false,
      domName: "",
      page: 1,
      pageSize: 10,
      list: [],
      typeList: [],
      allTemplateList: {},
      templateList: [],
      curTemp: {},
      listLoading: false,
      total: 0,
      isScroll: false,
      allowScroll: true,
      context: false,
      isFold: false,
      gpttype: "qwen-turbo",


      eventSource: null,
      aiResult:""
    };
  },
  mounted() {
    this.domName = document.querySelector(".chatgpt-cont-top");
    this.domName.addEventListener("scroll", this.handleScroll);
    this.getForm();
    this.getList();
  },
  methods: {
    Keydown(e) {
      if (e.keyCode == 13 && e.ctrlKey) {
        e.cancelBubble = true; //ie阻止冒泡行为
        e.stopPropagation(); //Firefox阻止冒泡行为
        // 阻止原生的换行事件
        e.preventDefault();
        // 手动换行
        this.questionTxt += "\n";
      } else if (e.keyCode == 13) {
        e.cancelBubble = true; //ie阻止冒泡行为
        e.stopPropagation(); //Firefox阻止冒泡行为
        // 阻止原生的换行事件
        e.preventDefault();
        // TODO: 发消息
        this.sendMessage();
      }
    },
    hasScroll() {
      if (
        this.domName.scrollHeight > this.domName.clientHeight ||
        this.domName.offsetHeight > this.domName.clientHeight
      ) {
        return true;
      } else {
        return false;
      }
    },
    handleScroll() {
      const scrollTop = this.getScroll().top;
      this.isScroll = true;
      if (
        scrollTop < 30 &&
        this.list.length < this.total &&
        !this.listLoading
      ) {
        if (this.page > 20) {
          return;
        }
        this.page += 1;
        this.getList();
      }
    },
    //获得页面向左、向上卷动的距离
    getScroll() {
      return {
        left:
          this.domName.pageXOffset ||
          this.domName.scrollLeft ||
          this.domName.scrollLeft ||
          0,
        top:
          this.domName.pageYOffset ||
          this.domName.scrollTop ||
          this.domName.scrollTop ||
          0,
      };
    },
    getForm() {
      let params = {
        page: this.page,
        pageSize: this.pageSize,
      };
      this.listLoading = true;
      this.allowScroll = false;
      this.api.system.systemChatgptList(params).then((res) => {
        this.listLoading = false;
        this.typeList = [];
        for (let name in res.types) {
          this.typeList.push({
            name: res.types[name],
            id: name,
          });
        }

        this.curType = this.typeList[0];
        this.allTemplateList = res.templates;
        this.templateList = this.allTemplateList[this.gpttype];
      });
    },
    getList(type) {
      let params = {
        page: this.page,
        pageSize: this.pageSize,
      };
      this.listLoading = true;
      this.allowScroll = false;
      this.api.system
        .systemChatgptList(params)
        .then((res) => {
          this.listLoading = false;
          // this.list = [
          //   ...res.list.reverse(),
          //   ...this.list,
          // ];
          let arr = [];
          for (let i = res.list.length - 1; i >= 0; i--) {
            arr.push(res.list[i]);
          }
          console.log(this.list);
          if (type == "send") {
            this.list = JSON.parse(JSON.stringify(arr));
          } else {
            this.list.unshift(...arr);
          }

          // console.log(this.list,'this.list')
          this.list.forEach((item) => {
            if (item.created_at.indexOf("-") == -1) {
              item.created_at = util.timeFormatter(
                new Date(+item.created_at * 1000),
                "yyyy-MM-dd hh:mm:ss"
              );
            }

            if (item.updated_at.indexOf("-") == -1) {
              item.updated_at = util.timeFormatter(
                new Date(+item.updated_at * 1000),
                "yyyy-MM-dd hh:mm:ss"
              );
            }
          });

          this.$nextTick(() => {
            // console.log(this.hasScroll(),'this.hasScroll()')

            if (!this.hasScroll()) {
              if (res.count > this.page * 10) {
                this.page += 1;
                this.getList();
              }
            } else {
              if (type == "send") {
                this.isScroll = false;
              }
              // console.log(this.isScroll,'this.isScrollthis.isScrollthis.isScroll')
              if (!this.isScroll) {
                this.domName.scrollTop = this.domName.scrollHeight;
                this.$nextTick(() => {
                  this.domName.scrollTop = this.domName.scrollHeight;
                  this.allowScroll = true;
                });
              } else {
                this.allowScroll = true;
              }

              // if(this.domName.scrollTop == 0){
              //   this.handleScroll();
              // }
            }
          });

          this.total = res.count;
        })
        .catch((err) => {
          this.listLoading = false;
        });
    },

    radioChange(id) {
      this.templateList = this.allTemplateList[id];
    },

    selectTemp(data) {
      this.curTemp = data;
      this.questionTxt = this.curTemp.content;
    },
    sendMessage() {
      if (this.loading) {
        this.$Message.warning("请等上一次结果返回后继续查询～");
        return;
      }
      if (!this.questionTxt) {
        this.$Message.warning("请输入问题～");
        return;
      }

      let params = {
        message: this.questionTxt,
        model: this.gpttype,
      };
      if (this.list.length > 0 && this.context) {
        params.last_question = this.list[this.list.length - 1].keyword;
        params.last_answer = this.list[this.list.length - 1].result
          ? this.list[this.list.length - 1].result
          : "";
      }
      this.loading = true;
      this.list.push({
        keyword: this.questionTxt,
        result: "加载中...",
      });
      this.$nextTick(() => {
        this.domName.scrollTop = this.domName.scrollHeight;
      });
      this.api.system
        .systemChatgpt(params)
        .then((res) => {
          this.page = 1;
          // this.list = [];
          this.isScroll = false;
          this.getList("send");
          this.questionTxt = "";
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  destroyed() {
    this.domName.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
.chatgpt {
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  height: 100%;

  .chatgpt-cont {
    height: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
  }

  .chatgpt-cont-l {
    padding: 40px 0px 40px 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    .loading {
      animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
      from {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(180deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    .loading-box {
      text-align: center;
    }
    .chatgpt-cont-top {
      padding: 23px 40px;
      flex: 1;
      background: #f7f7f7;
      color: #999999;
      overflow-y: auto;

      .message-type {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
      .message-cont {
        max-width: 75%;
        .message-cont-date {
          margin-top: 6px;
          margin-bottom: 8px;
        }
      }
      .message-cont-nr {
        padding: 10px 14px;
        white-space: pre-wrap;
        word-wrap: break-word;
        word-break: break-all;
      }
      .align-left {
        display: flex;
        justify-content: flex-start;

        .message-cont-nr {
          border-radius: 0px 8px 8px 8px;
          background-color: #ffffff;
          color: #333333;
        }
      }
      .align-right {
        display: flex;
        justify-content: flex-end;
        .message-cont-date {
          text-align: right;
        }
        .message-cont-nr {
          background: #4a79ff;
          border-radius: 8px 0px 8px 8px;
          color: #ffffff;
        }
      }
    }
    .chatgpt-cont-bottom {
      margin-top: 24px;
      height: 126px;

      .chatgpt-cont-bottom-version {
        width: 250px;
        height: 34px;
        background: #666666;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .chatgpt-cont-bottom-cont {
        padding: 10px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 92px;
        background: #f7f7f7;
        border: 1px solid #ecedf2;
        .chatgpt-cont-bottom-cont-text {
          flex: 1;
        }

        .chatgpt-cont-bottom-cont-btn {
          margin-left: 20px;
          width: 70px;
          height: 60px;
          background: #4a79ff;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
  .zhedie {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    > div {
      cursor: pointer;
      font-weight: bold;
    }
  }
  .chatgpt-temp {
    // margin-left: 30px;
    padding: 40px 40px 40px 0;
    width: 418px;
    display: flex;
    height: 100%;
    flex-direction: column;
    .chatgpt-temp-tit {
      margin-bottom: 10px;
      height: 25px;
      font-size: 16px;
      font-weight: bold;
      color: #4a79ff;
    }
    .chatgpt-temp-list {
      flex: 1;
      overflow: auto;
      height: 0;
      .chatgpt-temp-list-item {
        margin-bottom: 10px;
        padding: 20px;
        background: #f7f7f7;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        cursor: pointer;
        .chatgpt-temp-list-item-tit {
          margin-bottom: 10px;
          font-weight: bold;
          color: #333333;
        }
      }
      .active {
        background: #4a79ff;
        color: #ffffff;
        .chatgpt-temp-list-item-tit {
          color: #ffffff;
        }
      }
    }
  }
  //.chatgpt-cont{
  //  margin-bottom: 100px;
  //}
  //.item{
  //  .tit{
  //    background-color: #FFFFFF;
  //
  //
  //    .tit-cont{
  //      margin: 0 auto;
  //      width: 1000px;
  //      padding: 20px;
  //      display: flex;
  //      justify-content: flex-start;
  //
  //      >div:nth-child(1){
  //        width: 50px;
  //      }
  //      >div:nth-child(2){
  //        flex: 1;
  //        width: 0;
  //      }
  //    }
  //  }
  //  .des{
  //
  //    .des-cont{
  //      margin: 0 auto;
  //      width: 1000px;
  //      padding: 20px;
  //      display: flex;
  //      justify-content: flex-start;
  //
  //      >div:nth-child(1){
  //        width: 50px;
  //      }
  //      >div:nth-child(2){
  //        flex: 1;
  //        width: 0;
  //      }
  //    }
  //  }
  //}
  .no-data {
    padding-top: 100px;
    text-align: center;
  }
  //.bottom{
  //  position: fixed;
  //  bottom: 0;
  //  right: 0;
  //  width: calc(100% - 200px);
  //  //padding: 20px 0;
  //  background-color: #FFFFFF;
  //  border-top: 1px solid #dcdee2;
  //
  //
  //  .bottom-cont{
  //    margin: 30px auto;
  //    width: 600px;
  //    display: block;
  //    display: flex;
  //    justify-content: space-between;
  //    align-items: center;
  //
  //    .bottom-inp{
  //      margin-right: 20px;
  //    }
  //  }
  //
  //}
}
</style>
<style>
.chatgpt .ivu-input {
  border: none;
  background: none;
}
.chatgpt-cont-bottom-cont-text textarea {
  resize: none;
}
</style>
